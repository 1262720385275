import React from 'react';
import { Icon, UploadFile } from 'components';
import { prefixCls } from './index.scss';
import extraGlobalState from '@/utils/extraGlobalState';

/**
 * 需求 CYTRD-15888，上传图片支持上传问题
 * UploadFile 上传文件后返回的格式（不使用oss上传的数据结构）
 * file_type: "image",
 * name: "1724381361001e564f2a6.jpg", // 若为文件，name 为上传的文件名，若为图片，name 为图片的 id
 * path:  "beta/518/customer_goods/file",
 * showName: "文件名.jpg",
 * type: "customer_goods",
 *
 * 原 UploadImgText 上传返回格式
 * name: "img_phpGinNmh.jpeg"
 * origin_name: "123123.png"
 * path: "2024-08-23"
 * type: "balance"
 */

function renderImg(page) {
  const changeImg = files => {
    const fileList = files.map(item => ({
      ...item,
      origin_name: item.origin_name || item.showName || item.name, // 兼容历史数据，和后端接口数据，接口不会存 showName
    }));
    page.set('coImg', fileList);
  };

  return function () {
    const { store } = page;
    const state = store.getState();
    const { coImg = [], coImgShow, coImgEditable = true } = state;
    const isHidden = state.imgHidden ? ' hidden' : '';

    const toggle = () => page.set('imgHidden', !state.imgHidden);
    if (!coImgShow) {
      return null;
    }
    const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');
    const fileList = coImg.map(item => ({
      ...item,
      showName: item.origin_name || item.showName || item.name, // 预览下载文件名称，优先使用的是 showName，其次取 name
    }));

    return (
      <div className={`${prefixCls} order-card ${isHidden}`}>
        <h3>
          <Icon iconType="icon-carry-out" onClick={toggle} />
          附件
        </h3>
        <div className="file-wrap">
          <UploadFile
            type="balance"
            fileList={fileList}
            onChange={changeImg}
            disabled={!coImgEditable}
            acceptFileTypes="image/*,.pdf,.doc,.docx,.xls,.xlsx"
            accept="file"
            maxNum={10}
            sizeLimit={8}
            isOss={useNewWayEnabled}
          />
          <span className="tip">*最多上传10个</span>
        </div>
      </div>
    );
  };
}

export default renderImg;
