// import React from 'react'
import { assignedApprover } from 'components/commoncomponents/ApproverSelection/index';
import { CHECK, ERROR, WARN } from 'constants';
import _ from 'lodash';
import { createUploadFiles } from 'utils/imageService';
import browserHistory from 'routes/history';
import { PopUp, ShowInfo, ModalDialog, Button, TextArea, DialogDetail } from 'components';
import React from 'react';
import { showInfo, fetchApi, fetchJAVA, opTips, hasPermission, confirmWithReason, confirm, checkSelected } from 'utils';
import ApprovalEditor from 'pages/ApprovalController/Editor';
import Confirm from 'pages/ApprovalController/Confirm';
import AuditTrail from 'pages/ApprovalController/AuditTrail';
import AuditModal from 'pages/ApprovalController/audit';
import GlobalSetting from 'pages/ApprovalController/GlobalSetting';
import applySlideDrager from 'pages/FinanceController/applySlideDrager';
import { dataAnalyticTrack } from 'utils/dataAnalytic';
import { sentryReporter } from 'utils/sentryHelper';
import BatchOp from '../BatchOp';
import { rebutConfirm } from '../capitalFlow/tool';
// eslint-disable-next-line import/no-cycle
import {
  auditExpense,
  cancelCheckBalance,
  checkBalance,
  cusAudit,
  customerCancelAudit,
  pointAcCancelAudit,
  pointAudit,
  receiptPaymentAudit,
} from './financeOp';
// eslint-disable-next-line import/no-cycle
import { initEmt } from './financialOp';

const confirmAsync = type =>
  new Promise(resolve => {
    confirmWithReason({
      title: '操作确认',
      iconType: WARN,
      tips: `您确定要${type}审批设置吗？`,
      noticeTitle: `${type}后`,
      reason: (
        <ul>
          <li>● 之前已提交的申请，按改前的审批流程操作。</li>
          <li>● 之后新提交或修改的申请，按改后的审批流程操作。</li>
        </ul>
      ),
      onConfirm: cb => {
        cb();
        resolve(true);
      },
      onCancel: () => resolve(false),
    });
  });
const config = {
  delete: {
    desc: '删除',
    key: 'delete',
    api: 'Basic/Approval/deleteApproval', // api url
    beforeCheck: ids => ({ ids, check: 1 }), // keys to fetch body
    beforeSubmit: ids => ({ ids, check: 0 }),
  },
  enable: {
    desc: '启用',
    key: 'enable',
    api: 'Basic/Approval/opApproval',
    beforeCheck: ids => ({ ids, state: 1, check: 1 }),
    beforeSubmit: ids => ({ ids, state: 1, check: 0 }),
  },
  disable: {
    desc: '停用',
    key: 'disable',
    api: 'Basic/Approval/opApproval',
    beforeCheck: ids => ({ ids, state: 2, check: 1 }),
    beforeSubmit: ids => ({ ids, state: 2, check: 0 }),
  },
  rollbackDef: {
    desc: '恢复默认',
    api: 'Basic/Approval/rollbackDef',
    beforeSubmit: id => ({ id }),
  },
  update: {
    api: 'Basic/Approval/editApproval',
  },
};

const check = (type, res, callback) =>
  opTips({
    // 检查结果 并提示
    opType: type.key,
    orderType: '审批',
    title: `${type.desc}审批`,
    tips: `${type.desc}成功！`,
    resData: res,
    continueCallback: async (ids, cb) => {
      const result = await fetchApi(type.api, { method: 'POST', body: { req: type.beforeSubmit(ids) } });
      cb && cb();
      if (result.errno === 0) {
        showInfo(CHECK, `${type.desc}成功!`);
      } else {
        showInfo(ERROR, result.errmsg || `${type.desc}失败!`);
      }
      callback && callback();
    },
    sucCallback: () => {
      callback && callback();
    },
  });
const opearte = async ({ type, listPage }) => {
  // 支持停用 启用 删除
  const keys = listPage.getSelectesKey('id');
  console.log('kes=>', keys);
  // let keys = [1, 2, 3]
  const doOperate = async () => {
    const res = await fetchApi(type.api, { method: 'POST', body: { req: type.beforeCheck(keys) } });
    const startTime = new Date().getTime();
    check(type, res, () => {
      const endTime = new Date().getTime();
      const time = endTime - startTime > 2000 ? 0 : 2000 - (endTime - startTime);
      setTimeout(listPage.handleRefreshPage, time);
    });
  };
  if (!keys || !keys.length) {
    showInfo(ERROR, `请选择要${type.desc}的数据！`);
  } else if (type.desc !== '启用') {
    // eslint-disable-next-line no-shadow
    const confirm = await confirmAsync(type.desc);
    if (!confirm) {
      return;
    }
    await doOperate();
  } else {
    new PopUp(ShowInfo, {
      title: `${type.desc}审批`,
      type: WARN,
      content: `您确定要${type.desc}吗？`,
      simpleMode: {
        confirm: async t => {
          t.changeBtnLoadingStatus('confirm', true);
          await doOperate();
          t.handleHide();
        },
        cancel: t => t.handleHide(),
      },
    }).show();
  }
};
const jump2Approal = (listPage, res) => {
  new PopUp(ShowInfo, {
    type: WARN,
    content: res.errmsg,
    simpleMode: {
      confirm: t => {
        console.log('view');
        t.handleHide();
        let action = {};
        const filter = {};
        const param = { ignore_default: true };
        switch (res.res.trigger_operation) {
          case 'create_expense': // 报销单
            action = {
              controller: 'Finance',
              action: 'dailyIOSummary',
            };
            filter.audit_status_disp = [2, 6];
            filter.bill_sort = 1;
            break;
          case 'create_receipt': // 收据
            filter.audit_status_disp = [2, 6];
            filter.bill_sort = 2;
            action = {
              controller: 'Finance',
              action: 'dailyIOSummary',
            };
            break;
          case 'add_abnormal': // 异常
            filter.state = [10, 25];
            action = {
              controller: 'CutomerService',
              action: 'abnormal',
            };
            break;
          case 'mod_list': // eslint-disable-line
          case 'submit_om':
            filter.audit_st = [1, 5];
            action = {
              controller: 'Order',
              action: 'changeOrderManager',
            };
            break;
          case 'add_penalty': // 罚款
            filter.state = [10, 25];
            action = {
              controller: 'CutomerService',
              action: 'punish',
            };
            break;
          case 'mod_list': // eslint-disable-line
          case 'submit_om': // eslint-disable-line
            filter.audit_st = [1, 5];
            action = {
              controller: 'Order',
              action: 'changeOrderManager',
            };
            break;
          case 'order_receipt_apply':
          case 'order_payment_apply':
          case 'tr_start_payment_apply':
          case 'shuttle_payment_apply':
          case 'tr_start_receipt_apply':
          case 'shuttle_receipt_apply': // eslint-disable-line
            filter.pa_re_audit = [6, 2];
            action = {
              controller: 'Finance',
              action: 'paymentReceivedBillList',
            };
            break;
          case 'cor_ca_apply':
            filter.customer_type = ['customer_consignor'];
            filter.pa_re_audit = [6, 2];
            action = {
              controller: 'Finance',
              action: 'cliRelList',
            };
            break;
          case 'cee_ca_apply':
            filter.customer_type = ['customer_consignee'];
            filter.pa_re_audit = [6, 2];
            action = {
              controller: 'Finance',
              action: 'cliRelList',
            };
            break;
          case 'carrier_ca_apply':
            filter.customer_type = ['customer_partner'];
            filter.pa_re_audit = [6, 2];
            action = {
              controller: 'Finance',
              action: 'cliRelList',
            };
            break;
          case 'trans_point_ca_apply':
            filter.customer_type = ['customer_transpoint'];
            filter.pa_re_audit = [6, 2];
            action = {
              controller: 'Finance',
              action: 'cliRelList',
            };
            break;
          case 'create_point':
            filter.pa_re_audit = [6, 2];
            action = {
              controller: 'Finance',
              action: 'branchRecList',
            };
            break;
          default:
            browserHistory.pushWithQuery('/NotFound/404');
            return;
        }
        const _designateFilter = Object.assign({}, filter);
        const _designateParam = Object.assign({}, param);
        const pack = {
          designateParam: _designateParam,
          // designateFilter: { settle_no: settleNo },
          designateFilter: _designateFilter,
          initFilterHeader: listPage.getHeader(),
        };
        action.injectProps = pack;
        window.$app.direct(action);
      },
      cancel: t => {
        console.log('cancel');
        t.handleHide();
      },
    },
    simpleText: {
      confirm: '查看未完成审批',
      cancel: '取消',
    },
  }).show();
};
const onRollback = async (listPage, rId, cId, row) => {
  // 恢复默认
  // AuditTrail()
  const type = config.rollbackDef;
  const { id } = row;
  // eslint-disable-next-line no-shadow
  const confirm = await confirmAsync(type.desc);
  if (!confirm) {
    return;
  }
  const res = await fetchApi(type.api, { method: 'POST', body: { req: type.beforeSubmit(id) } });
  if (res.errno === 0) {
    showInfo(CHECK, `${type.desc}成功!`);
    setTimeout(listPage.handleRefreshPage, 2000);
  } else if (res.errno === -904) {
    // 有未完成的审批 跳转
    jump2Approal(listPage, res);
  } else {
    showInfo(WARN, res.errmsg || '有未完成的审批，不允许操作!');
  }
};
/**
 * 每当新增一种审批设置都需要维护此方法
 * @param {*} listPage
 * @param {*} rest
 */
const onUpdate = async (listPage, ...rest) => {
  // 跟新审批
  const ids = listPage.getSelectesKey(['id']);
  if (ids.length === 0) {
    showInfo(ERROR, '请选择要修改的数据！');
  } else if (ids.length > 1) {
    showInfo(ERROR, '修改不支持批量操作');
  } else {
    const id = ids[0];
    ApprovalEditor.show({
      id,
      opType: 'edit',
      onSuccess: () => setTimeout(listPage.handleRefreshPage, 2000),
    });
  }
};
const viewApproval = async (listPage, rId, cId, row) => {
  // 恢复默认
  const { id } = row;
  ApprovalEditor.show({ opType: 'view', id });
};

// 审核 参数配置。根据单据的系分类型 映射到一个统一的类型。主要用于兼容 老旧的使用到 auditKeyMap 的场景。
const AUDIT_MASS_PRAMA = {
  // 收款单 -- 订单对账单 -- 客户订单对账单
  receipt: {
    operation: 'receipt_payment',
  },
  // 收款单 -- 运单收款单 -- 客户运单收款单
  receipt_batch_task: {
    operation: 'receipt_payment',
  },
  // 收款单 -- 运单对账单 -- 项目
  customer_batch_project: {
    operation: 'create_account',
  },
  // 收款单 -- 运单对账单 -- 客户
  customer_batch_client: {
    operation: 'create_account',
  },

  // 付款单 -- 承运商对账单
  customer_batch_team: {
    operation: 'create_account',
  },
  // 付款单 -- 车辆对账单
  customer_batch_car: {
    operation: 'create_account',
  },
  // 付款单-- 司机对账单
  customer_batch_driver: {
    operation: 'create_account',
  },

  // 司机报销单
  payment_batch_onway: {
    operation: 'receipt_payment',
  },

  // 司机还款单
  repay_driver: {
    operation: 'loan_audit',
    getCurOperation: () => 'repayment_driver_apply',
  },
  // 司机借款单
  loan_driver: {
    operation: 'loan_audit',
    getCurOperation: () => 'loan_driver_apply',
  },
  // 车辆借款单
  loan_car: {
    operation: 'loan_audit',
    getCurOperation: () => 'loan_car_apply',
  },
  // 车辆还款单
  repay_car: {
    operation: 'loan_audit',
    getCurOperation: () => 'repayment_car_apply',
  },
  // 员工借款单
  loan_employee: {
    operation: 'loan_audit',
    getCurOperation: () => 'loan_user_apply',
  },
  // 员工还款单
  repay_employee: {
    operation: 'loan_audit',
    getCurOperation: () => 'repayment_user_apply',
  },
  // 日常收支
  // 日常收支的单据类型有点乱，这里日常收支-报销单、日常收支-收据、日常收支-收支付款单需要单独处理
  balance: {
    operation: 'expense_or_receipt',
    getCurOperation: data => (data.bill_sort === '1' ? 'create_expense' : 'create_receipt'),
  },
};

/**
 * 审批 配置
 */
const auditKeyMap = {
  loan_audit: {
    // 借支管理 批量审核 批量取消审核
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '单据',
    table: 'bill',
    diff: 'bill_cat',
    number: 'bill_no',
  },
  expense_or_receipt: {
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '单据',
    per: 'expenseApproval',
    diff: 'bill_sort',
    number: 'balance_no',
    extraKeys: ['bill_sort'],
  },
  create_expense: {
    // 创建报销单
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '报销单',
    per: 'expenseApproval',
    number: 'balance_no',
  },
  create_receipt: {
    // 创建收据
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '收据',
    per: 'receiptApproval',
    number: 'balance_no',
  },
  payment_apply: {
    // 收付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '收款单',
    table: 'bill',
    number: 'bill_no',
    showUploadImg: true,
  },
  receipt_apply: {
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '付款单',
    table: 'bill',
    number: 'bill_no',
    showUploadImg: true,
  },
  receipt_payment: {
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '账单',
    table: 'bill',
    number: 'bill_no',
    showUploadImg: true,
  },
  create_account: {
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '对账单',
    table: 'bill',
    number: 'bill_no',
    showUploadImg: true,
    extraKeys: ['customer_type'],
  },
  document_center: {
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '单据',
    table: 'bill',
    number: 'bill_no',
  },
  create_em_account: {
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '员工交账单',
    showUploadImg: true,
  },
  add_abnormal: {
    applyId: 'id',
    auditStatus: 'audit_status_disp',
    desc: '异常',
    number: 'number',
    per: 'abnormalApproval',
    create_by: 'add_user_id',
    company_id: 'add_company_id',
  },
  submit_om: {
    applyId: 'id',
    auditStatus: 'audit_status_disp',
    desc: '改单',
    number: 'order_num',
  },
  add_penalty: {
    applyId: 'id',
    auditStatus: 'audit_status_disp',
    desc: '罚款',
    number: 'number',
    per: 'penaltyApproval',
    create_by: 'add_user_id',
    company_id: 'add_company_id',
  },
  default: {
    // 默认
    applyId: 'bill_id',
    auditStatus: 'audit_status_disp',
    desc: '单据',
  },
  order_payment_apply: {
    // 运单付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '运单付款单',
    per: 'orderPaymentApproval',
    number: 'bill_no',
  },
  tr_start_payment_apply: {
    // 发车批次付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '发车批次付款单',
    per: 'trStartPaymentApproval',
    number: 'bill_no',
  },
  shuttle_payment_apply: {
    // 短波批次付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '短波批次付款单',
    per: 'shuttlePaymentApproval',
    number: 'bill_no',
  },
  onway_payment_apply: {
    // 在途费用付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '在途费用付款单',
    per: 'paymentOnWayApproval',
    number: 'bill_no',
  },
  order_receipt_apply: {
    // 运单收款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '运单收款单',
    per: 'orderReceiptApproval',
    number: 'bill_no',
  },
  tr_start_receipt_apply: {
    // 发车批次付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '发车批次付款单',
    per: 'trStartReceiptApproval',
    number: 'bill_no',
  },
  shuttle_receipt_apply: {
    // 短波批次付款
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '短波批次付款单',
    number: 'bill_no',
  },
  cor_ca_apply: {
    // 发货客户对账单
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '发货客户对账单',
    per: 'corCAApproval',
    number: 'bill_no',
  },
  cee_ca_apply: {
    // 收货客户对账单
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '收货客户对账单',
    per: 'ceeCAApproval',
    number: 'bill_no',
  },
  carrier_ca_apply: {
    // 承运商对账单
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '承运商对账单',
    per: 'carrierCAApproval',
    number: 'bill_no',
  },
  create_dr_account: {
    // 承运商对账 - 司机对账
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '承运商对账单',
    per: 'carrierCAApproval',
    number: 'bill_no',
  },
  trans_point_ca_apply: {
    // 承运网点对账单
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '承运网点对账单',
    per: 'transPointCAApproval',
    number: 'bill_no',
  },
  point_ac_audit: {
    // 网点对账
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '网点对账单',
    per: 'pointAcAudit',
    number: 'bill_no',
    showUploadImg: true,
  },
  create_point: {
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '网点对账单',
    per: 'pointApproval',
    number: 'bill_no',
    showUploadImg: true,
  },
  oms_carrier_ca_apply: {
    // 承运商对账单
    applyId: 'bill_id',
    auditStatus: 'pa_re_audit',
    desc: '承运商对账单',
    per: 'omsCarrierCAApproval',
    number: 'bill_no',
  },
  money_flow_apply: {
    applyId: 'bill_id',
    auditStatus: 'settle_audit',
    desc: '资金流水审核单',
    number: 'bill_no',
    force: true,
    unsupportBatch: true,
  },
  batch_modify_apply: {
    applyId: 'id',
    desc: '改单申请',
    auditStatus: 'audit_state',
  },
  user_invoice_apply: {
    applyId: 'bill_id',
    desc: '发票申请',
    auditStatus: 'audit_status_disp',
    number: 'bill_no',
  },
  customer_invoice_apply: {
    applyId: 'bill_id',
    desc: '发票索要申请',
    auditStatus: 'audit_status_disp',
    number: 'bill_no',
  },
  batch_assess_customer_apply: {
    applyId: 'id',
    desc: '客户考核',
    auditStatus: 'assess_state',
  },
  batch_assess_trans_apply: {
    applyId: 'id',
    desc: '运力考核',
    auditStatus: 'assess_state',
    // table: 'bill',
    number: 'assess_code',
    bill_no: 'assess_code',
    create_by: 'register_by',
    company_id: 'register_com_id',
    showUploadImg: true,
  },
  fee_report_apply: {
    applyId: 'id',
    desc: '在途费用上报',
    auditStatus: 'batch_audit_state',
  },
  material_apply: {
    applyId: 'id',
    number: 'apply_code',
    desc: '物料发放',
    extraKeys: ['apply_code', 'dr_name', 'tr_num', 'apply_user'],
    force: true,
  },
  tr_repair_apply: {
    applyId: 'id',
    desc: '车辆维修',
  },
  tr_maintain_apply: {
    applyId: 'id',
    desc: '车辆保养',
  },
  batch_create_apply: {
    applyId: 'batch_apply_id',
    desc: '运单确认',
  },

  contract_add_apply: {
    applyId: 'id',
    desc: '新增客户合同审批',
  },
  contract_modify_apply: {
    applyId: 'id',
    desc: '修改客户合同审批',
  },
  contract_delay_apply: {
    applyId: 'id',
    desc: '延期客户合同审批',
  },
  contract_carrier_add_apply: {
    applyId: 'id',
    desc: '新增承运商合同审批',
  },
  contract_carrier_modify_apply: {
    applyId: 'id',
    desc: '修改承运商合同审批',
  },
  contract_carrier_delay_apply: {
    applyId: 'id',
    desc: '延期承运商合同审批',
  },
};

const getTable = operation => {
  const table = auditKeyMap[operation] && auditKeyMap[operation].table;
  return {
    operation: table ? '' : operation,
    table,
  };
};
const getIsApprovals = (selectedList, force) => {
  const flag = {
    isApproval: [],
    notApproval: [],
  };
  selectedList.forEach(item => {
    const { operate = [] } = item.otherProps;
    if (force || operate.find(os => os.key === 'audit_log')) {
      flag.isApproval.push(item);
    } else {
      flag.notApproval.push(item);
    }
  });
  return flag;
};
/**
 * 申请审批
 * operation 审批类型
 * data apply_data
 * uploadImgs 图片formData 例如 {'add_imgs[]': {img}}
 * applyId
 * 返回 res
 */
const Apply = async (operation, data, uploadImgs = {}, applyId = null, hiddenOrderInfo = false, questKey, that) => {
  const headerJons = { 1: '运单号', 3: '批次号', 4: '任务号' };
  const companyName = questKey === '1' ? '开单网点' : '网点';
  const header = {
    num_title: { title: headerJons[questKey], display: 'show', dispay: 'show' },
    company_name: { title: companyName, display: 'show', dispay: 'show' },
  };
  const tips = '申请';
  const dataType = _.isArray(data);
  const newData = dataType || !hiddenOrderInfo ? data : data.num_title.map(() => ({}));
  if (!dataType && hiddenOrderInfo) {
    Object.keys(data).forEach(key => {
      if (_.isArray(data[key])) {
        data[key].forEach((v, i) => {
          newData[i][key] = [v];
        });
      } else {
        newData.forEach((v, i) => {
          newData[i][key] = data[key];
        });
      }
    });
  }
  const appoint_user = await assignedApprover({ operation, apply_data: data, apply_id: applyId });
  if (!appoint_user) return;

  if (!hiddenOrderInfo || newData.length === 1) {
    const body = new FormData();
    body.append(
      'req',
      JSON.stringify({ operation, apply_data: data, apply_id: applyId, appoint_user: appoint_user?.id }),
    );
    if (uploadImgs && Object.keys(uploadImgs).length) {
      Object.entries(uploadImgs).forEach(([key, imgsObj]) => {
        Object.entries(imgsObj).forEach(([, imgObj]) => {
          body.append(key, imgObj.formdata);
        });
      });
    }
    return fetchApi('Basic/Approval/apply', {
      method: 'POST',
      credentials: 'include',
      body,
    });
  }
  return new Promise(resolve => {
    new PopUp(BatchOp, {
      title: '批量登记',
      tips,
      header,
      data: newData,
      requestUrl: 'Basic/Approval/apply',
      getParaType: 'body',
      getPara: dataList => {
        const theData = dataType ? [{ ...dataList[0] }] : { ...dataList[0] };
        delete theData.otherProps;
        delete theData.status_icon;
        delete theData.status_text;
        delete theData.index;
        const body = new FormData();
        body.append(
          'req',
          JSON.stringify({
            operation,
            apply_data: theData,
            apply_id: applyId,
            appoint_user: appoint_user?.id,
            is_batch_op: true, // 仅用来标识 是否 通过批量弹框操作的，后端不会使用
          }),
        );
        if (uploadImgs && Object.keys(uploadImgs).length) {
          Object.entries(uploadImgs).forEach(([key, imgsObj]) => {
            Object.entries(imgsObj).forEach(([, imgObj]) => {
              body.append(key, imgObj.formdata);
            });
          });
        }

        return body;
      },
      finishCallback: () => {
        that.refreshTab();
        that.close();
      },
      escBind: false,
    }).show();
  });
};
const updateApply = (operation, data, uploadImgs = {}, applyId = null) => {
  const body = new FormData();
  body.append('req', JSON.stringify({ ...getTable(operation), apply_data: data, apply_id: applyId }));
  if (uploadImgs && Object.keys(uploadImgs).length) {
    Object.entries(uploadImgs).forEach(([key, imgsObj]) => {
      Object.entries(imgsObj).forEach(([, imgObj]) => {
        body.append(key, imgObj.formdata);
      });
    });
  }
  return fetchApi('Basic/Approval/auditToUpdateApply', {
    method: 'POST',
    credentials: 'include',
    body,
  });
};
const ApplyMass = (operation, data, uploadImgs = {}) => {
  const header = {
    order_num: { title: '运单号', display: 'show', dispay: 'show' },
    company_id: { title: '开单网点', display: 'show', dispay: 'show' },
  };
  const tips = '申请';
  const dataType = _.isArray(data);
  const newData = dataType ? data : data.order_num.map(() => ({}));
  if (!dataType) {
    Object.keys(data).forEach(key => {
      if (_.isArray(data[key])) {
        data[key].forEach((v, i) => {
          newData[i][key] = [v];
        });
      } else {
        newData.forEach((v, i) => {
          newData[i][key] = data[key];
        });
      }
    });
  }
  if (newData.length === 1) {
    const body = new FormData();
    body.append('req', JSON.stringify({ ...getTable(operation), apply_data: data }));
    if (uploadImgs && Object.keys(uploadImgs).length) {
      Object.entries(uploadImgs).forEach(([key, imgsObj]) => {
        Object.entries(imgsObj).forEach(([, imgObj]) => {
          body.append(key, imgObj.formdata);
        });
      });
    }
    return fetchApi('Basic/Approval/applyMass', {
      method: 'POST',
      credentials: 'include',
      body,
    });
  }
  return new Promise(resolve => {
    new PopUp(BatchOp, {
      title: '批量登记',
      tips,
      header,
      data: newData,
      requestUrl: 'Basic/Approval/applyMass',
      getParaType: 'body',
      getPara: dataList => {
        const theData = dataType ? [{ ...dataList[0] }] : { ...dataList[0] };
        delete theData.otherProps;
        delete theData.status_icon;
        delete theData.status_text;
        delete theData.index;
        const body = new FormData();
        body.append(
          'req',
          JSON.stringify({
            ...getTable(operation),
            apply_data: theData,
            is_batch_op: true, // 仅用来标识 是否 通过批量弹框操作的，后端不会使用
          }),
        );
        if (uploadImgs && Object.keys(uploadImgs).length) {
          Object.entries(uploadImgs).forEach(([key, imgsObj]) => {
            Object.entries(imgsObj).forEach(([, imgObj]) => {
              body.append(key, imgObj.formdata);
            });
          });
        }
        return body;
      },
      finishCallback: () => resolve(),
      escBind: false,
    }).show();
  });
};

/**
 * 审批
 * @param {* 触发操作}            operation
 * @param {* id}                 applyId
 * @param {* 审批结果 1通过 3拒绝} AUDIT
 * @param {* 审批意见}            opinion
 * @param {* 上传的图片附件}      uploadImgs
 * @param {* 上传的文件}         uploadFiles 上传的文件对象 { xxx_key :{ imgs: [], files: [] } }
 * table 当确定不了operation时传
 */
const Audit = (operation, applyId, AUDIT, opinion, data, uploadImgs, appointUser, { extReq } = {}, uploadFiles) => {
  const body = new FormData();
  const audit = (+AUDIT === 1 && '1') || (+AUDIT === 4 && '4') || '3';
  const req = { ...getTable(operation), audit, opinion, appoint_user: appointUser, data, ...extReq };
  // 如果 uploadFiles 有值，这用这个上传文件
  if (uploadFiles) {
    req.upload_files = uploadFiles;
  } else if (uploadImgs && Object.keys(uploadImgs).length > 0) {
    Object.entries(uploadImgs).forEach(([key, imgsObj]) => {
      Object.entries(imgsObj).forEach(([, imgObj]) => {
        body.append(key, imgObj.formdata);
      });
    });
    req.approval_img = Object.entries(uploadImgs).map(([, imgsObj]) => Object.keys(imgsObj))[0] || [];
  }
  body.append('req', JSON.stringify(req));
  return fetchApi('Basic/Approval/audit', {
    method: 'POST',
    credentials: 'include',
    body,
  });
};
/**
 * 是否应该走审批接口
 * 逻辑是：当key 在以下枚举之内且不具有权限时 走审批接口
 * @param {* string} butKey 按钮key
 */
const shouldDoAudit = butKey => {
  // 是否走审批接口
  let key = -1;
  switch (butKey) {
    case 'create_account':
      key = 'review_ac_op';
      break; // 创建对账单
    case 'create_em_account':
      key = 'employee_ac_op';
      break; // 创建交账单
    default:
      break;
  }
  // 当 butKey 不在枚举内 并且 没有权限 时返回true
  return key !== -1 && !hasPermission(key);
};
/**
 * 触发操作
 */
const applyOperation = {
  createAccount: 'create_account', // 创建对账单
  createEmAccount: 'create_em_account', // 创建交账单
  createExpense: 'create_expense', // 创建报销单
  createReceipt: 'create_receipt', // 创建 收据
  paymentApply: 'payment_apply',
  receiptApply: 'receipt_apply',
  submitOm: 'submit_om',
};
/**
 * 取消审批申请
 * @param {*} that
 * @param {*} operation
 * @param {*} param2
 */
const cancelApply = async (that, operation, { applyId, desc, buttonKey } = {}) => {
  console.log(that, operation, 'operationoperationoperation');
  let applyID = applyId;
  const operate = _.get(that.getSelectesKey('otherProps')[0], 'operate', []);
  const isAuditLog = applyId || operate.find(item => item.key === 'audit_log');
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const type = desc || theKeys.desc;
  console.log(that.getSelectesKey('id')[0], '4444444');
  if (!applyId) {
    const selectedList = that.getSelectesKey([theKeys.applyId]);
    if (!selectedList.length) {
      showInfo(ERROR, '请选择要操作的数据');
      return;
    }
    if (selectedList.length > 1) {
      showInfo(ERROR, `一次只能取消一条${type}`);
      return;
    }
    applyID = selectedList[0][theKeys.applyId];
  }
  if (!applyID) {
    console.log('快来改bug,applyId没有');
  }
  if (!isAuditLog) {
    switch (operation) {
      // 收付款单
      case 'receipt_payment':
        initEmt('receiptPaymentCancel', that);
        return;
      // 日常收支
      case 'expense_or_receipt':
        showInfo(ERROR, '您没有取消的权限');
        return;
      // 客户对账单
      case 'create_account':
        initEmt('customerCancel', that);
        return;
      // 网点对账单
      case 'create_point':
        initEmt('pointCancel', that);
        return;
      case 'customer_invoice_apply':
        break;
      default:
        showInfo(ERROR, '只能取消待审批的单据');
        return;
    }
  }
  const cancelCommon = () => {
    let _fetch = fetchApi;
    let fetchUrl = 'Basic/Approval/cancelApply';
    if (!isAuditLog && operation === 'create_account') {
      _fetch = fetchJAVA;
      fetchUrl = '/cmm-finance/japi/finance/cancel';
    }
    const customerType = that.getSelectesKey('customer_type');
    buttonKey && that?.disableMenuButton?.(buttonKey);
    _fetch(fetchUrl, {
      method: 'POST',
      body: {
        req: {
          type: customerType[0],
          apply_id: applyID,
          ...getTable(operation),
        },
      },
    })
      .then(res => {
        console.log(res, 'resultresult');
        if (+res.errno === 0) {
          showInfo(CHECK, `取消${type}成功`);
          setTimeout(that.handleRefreshPage, 2000);
        } else if (+res.errno === 509) {
          const content = (
            <div className="customer-dialog-content">
              <p className="big" style={{ fontSize: '16px', fontWeight: '650' }}>
                存在已审核或已结算的关联对账单无法取消审核！
              </p>
              <p className="big" style={{ fontSize: '12px', color: '#F59A23' }}>
                关联对账单：{res.errmsg}
              </p>
            </div>
          );
          confirm(WARN, content, { confirm: '关闭' }, '操作确认');
        } else {
          showInfo(WARN, res.errmsg);
        }
      })
      .catch(() => showInfo(ERROR, `取消${type}失败`))
      ?.finally?.(() => buttonKey && that?.enableMenuButton?.(buttonKey));
  };
  if (that.tableInfo.tab === 'customer_task') {
    const url = '/cmm-finance/japi/finance/cancelReleatedCheck';
    if (!that.getSelectesKey('bill_id') || !that.getSelectesKey('bill_id')[0]) return;
    const req = {
      id: that.getSelectesKey('bill_id')[0],
    };
    buttonKey && that?.disableMenuButton?.(buttonKey);
    const postDataCheck = await fetchJAVA(url, {
      method: 'POST',
      body: {
        req,
      },
    })?.finally?.(() => buttonKey && that?.disableMenuButton?.(buttonKey));
    // eslint-disable-next-line no-prototype-builtins
    const res = postDataCheck.hasOwnProperty('res') && postDataCheck.res.length !== 0;
    const content = (
      <div className="customer-dialog-content">
        <p className="big" style={{ fontSize: '16px', fontWeight: '650' }}>
          您确定要取消该{type}吗？
        </p>
        {res && (
          <p className="big" style={{ fontSize: '12px', color: '#F59A23' }}>
            取消后会同时取消对账单：{postDataCheck.res.join('，')}
          </p>
        )}
      </div>
    );
    confirm(WARN, content, { confirm: '确定', cancel: '取消' }, '操作确认').then(result => {
      result && cancelCommon();
      buttonKey && that?.enableMenuButton?.(buttonKey);
    });
    return;
  }
  cancelCommon();
};
const cancelApplyMass = (that, operation) => {
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const type = theKeys.desc;
  // 是否支持批量操作
  const supportBatch = !theKeys.unsupportBatch;
  const selectKeys = [
    theKeys.applyId,
    theKeys.number || 'number',
    theKeys.bill_no || 'bill_no',
    theKeys.company_id || 'company_id',
    theKeys.create_by || 'create_by',
    'otherProps',
    'id',
  ];
  const selectedList = that.getSelectesKey(selectKeys);
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  if (!supportBatch && selectedList.length > 1) {
    showInfo(ERROR, '您只能选择一条数据进行操作！');
    return;
  }
  const data = selectedList.map(v => {
    let comId = v[theKeys.company_id || 'company_id'];
    let createBy = v[theKeys.create_by || 'create_by'];
    try {
      createBy = that.getEnumItem(theKeys.create_by || 'create_by', createBy);
    } catch (error) {
      createBy = '';
    }
    if (typeof comId === 'string') {
      try {
        comId = that.getEnumItem(theKeys.company_id || 'company_id', comId);
      } catch (error) {
        comId = '';
      }
    }
    comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
    createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
    return {
      ...getTable(operation),
      apply_id: v[theKeys.applyId],
      number: v[theKeys.number || 'number'],
      company_id: comId,
      create_by: createBy,
      id: v.id,
    };
  });
  const header = {
    number: { title: '单据号', display: 'show', dispay: 'show' },
    company_id: { title: '申请组织', display: 'show', dispay: 'show' },
    create_by: { title: '申请人', display: 'show', dispay: 'show' },
  };
  const requestUrl = 'Basic/Approval/cancelApply';
  const reqFormater = dataList => {
    const req = { apply_id: dataList[0].apply_id, ...getTable(operation) };
    return req;
  };
  const handle = () => {
    if (data.length > 1) {
      new PopUp(BatchOp, {
        title: '批量取消申请',
        tips: '取消',
        header,
        data,
        requestUrl,
        orderType: type,
        prefixalTips: '以下申请',
        getPara: reqFormater,
        finishCallback: () => that.handleRefreshPage(),
        escBind: false,
      }).show();
    } else {
      fetchApi(requestUrl, {
        method: 'POST',
        body: {
          req: reqFormater(data),
        },
      }).then(res => {
        opTips({
          resData: res,
          orderType: '申请',
          opType: '取消',
          tips: '取消申请',
          sucCallback: () => {
            setTimeout(that.handleRefreshPage, 2000);
          },
        });
      });
    }
  };
  showInfo(WARN, `您确定要取消该${type}申请吗？`, true, { confirm: handle });
};
/**
 * 取消审核(不支持批量)
 * @param {*} that          listpage引用
 * @param {*} operation     操作类型
 * @param {} {applyId,desc}      applyId 如果不传根据 auditKeyMap 获取
 */
const cancelAudit = (that, operation, { applyId, desc } = {}) => {
  let applyID = applyId;
  let number;
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const type = desc || theKeys.desc;
  if (!applyId) {
    const selectedList = that.getSelectesKey([theKeys.applyId, theKeys.number || 'number']);
    if (!selectedList.length) {
      showInfo(ERROR, `请选择${type}`);
      return;
    }
    if (selectedList.length > 1) {
      showInfo(ERROR, `一次只能取消一条${type}`);
      return;
    }
    applyID = selectedList[0][theKeys.applyId];
    number = selectedList[0][theKeys.number];
  }
  if (!applyID) {
    console.log('快来改bug,applyId没有');
  }
  const handle = () =>
    fetchApi('Basic/Approval/cancelAudit', {
      method: 'POST',
      body: {
        req: {
          apply_id: applyID,
          ...getTable(operation),
          data: [
            {
              ...getTable(operation),
              apply_id: applyID,
              number,
            },
          ],
        },
      },
    })
      .then(res => {
        if (+res.errno === 0 && (!res.res.success || res.res.success.length)) {
          showInfo(CHECK, `取消${type}审核成功`);
          setTimeout(that.handleRefreshPage, 2000);
        } else {
          const errmsg = res.res.failed_detail ? res.res.failed_detail[0].msg : res.errmsg;
          showInfo(ERROR, errmsg || `取消${type}审核失败`);
        }
      })
      .catch(() => showInfo(ERROR, `取消${type}审核失败`));
  showInfo(WARN, `您确定要取消该${type}审核吗？`, true, { confirm: handle });
};
const newCheckMass = async (that, data, operation) => {
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  let per;
  // 未开启审批流的数据需要校验下是否有相关权限。
  switch (operation) {
    case 'add_abnormal':
      per = 'auditAbnormal';
      break;
    case 'add_penalty':
      per = 'auditPenalty';
      break;
    default:
      return;
  }
  let perDesc = '';
  per = hasPermission(per);
  let subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，请选择一类继续操作！';
  if (!per) {
    perDesc = '，如需审核，请联系管理员开通权限';
    subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，是否继续操作自定义审批流的单据？';
  }
  let selectedList = data;
  const flag = getIsApprovals(selectedList);
  let isApproval = true;
  let dataIndexs = [];
  if (flag.isApproval.length && flag.notApproval.length) {
    try {
      dataIndexs = await Confirm({
        iconType: WARN,
        subTitle,
        checked: per ? [] : [0],
        disabled: per ? [] : [1],
        notice: [
          {
            text: ' 以下单据按“自定义审批流”操作：',
            child: flag.isApproval.map(v => v[theKeys.number]),
          },
          {
            text: `以下单据按“系统默认审批流”操作${perDesc}：`,
            child: flag.notApproval.map(v => v[theKeys.number]),
          },
        ],
      });
    } catch (error) {
      console.log(error);
      return;
    }
    if (dataIndexs[0] === 0) {
      selectedList = flag.isApproval;
    } else {
      selectedList = flag.notApproval;
      isApproval = false;
    }
  }
  if (flag.notApproval.length && dataIndexs[0] !== 0) {
    isApproval = false;
  }
  return {
    selectedList,
    isApproval,
  };
};

// 过滤掉不能批量取消审核的数据
const _filterCanList = (list, { typeKey } = {}) => {
  let canList = [];
  let noList = [];
  for (let i = 0, len = list.length; i < len; i++) {
    const curRow = list[i];

    // 根据单据类型，判断是否可取消审核
    const { operation } = AUDIT_MASS_PRAMA[curRow[typeKey]] ?? {};
    const theKeys = auditKeyMap[operation] || auditKeyMap.default;

    const flag = getIsApprovals([curRow], theKeys.force);
    canList = [...canList, ...flag.isApproval];
    noList = [...noList, ...flag.notApproval];
  }

  // 如果存在默认审批流，不能批量发起取消审核
  if (noList.length) {
    const mockFail = noList.map(item => ({ msg: '未配置审批流，无法批量取消审核', number: item.bill_no }));
    const mockRes = { errno: 1, res: { failed_detail: mockFail } };
    opTips({
      resData: mockRes,
      orderType: '单据',
      ext: {
        customFailDesc: '以下单据无法批量取消审核，请检查后重新选择!',
      },
    });
    return [];
  }
  return canList;
};

// 格式化批量取消审核的数据
const _formatCanList = (that, list, { typeKey } = {}) => {
  return list.map(row => {
    // 根据单据类型，拼审核数据
    const { operation, getCurOperation } = AUDIT_MASS_PRAMA[row[typeKey]] ?? {};
    const theKeys = auditKeyMap[operation] || auditKeyMap.default;

    ['number', 'bill_no', 'company_id', 'create_by'].forEach(key => (theKeys[key] = theKeys[key] || key));

    let comId = row[theKeys.company_id];
    let createBy = row[theKeys.create_by];
    try {
      createBy = that.getEnumItem(theKeys.create_by, createBy);
    } catch (error) {
      createBy = '';
    }
    if (typeof comId === 'string') {
      try {
        comId = that.getEnumItem(theKeys.company_id, comId);
      } catch (error) {
        comId = '';
      }
    }
    comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
    createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
    return {
      ...getTable(getCurOperation ? getCurOperation(row) : operation),
      apply_id: row[theKeys.applyId],
      number: row[theKeys.number],
      bill_no: row[theKeys.bill_no],
      company_id: comId,
      create_by: createBy,
      id: row.id,
    };
  });
};

// 通用的取消审核
const cancelAuditUniversal = async (that, buttonKey, { typeKey = 'customer_type' } = {}) => {
  const selectedRows = that.getStateSelecteRows?.();
  if (!selectedRows.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }

  // 单挑数据，直接走对应单据的取消审核流程
  if (selectedRows.length === 1) {
    // 根据 单据类型 获得操作参数.主要是将单据细分类型，映射到一个统一的类型，方便兼容cancelAuditMass；
    const fisrtRow = selectedRows[0];
    const { operation, getCurOperation, other = {} } = AUDIT_MASS_PRAMA[fisrtRow[typeKey]] ?? {};
    cancelAuditMass(that, operation, getCurOperation, { buttonKey, ...other });
    return;
  }

  const confirmCancelAudit = await confirm(WARN, '您确定要取消该单据审核吗？', undefined, '提示');
  if (!confirmCancelAudit) return;

  // 过滤掉不能批量取消审核的数据
  const canList = _filterCanList(selectedRows, { typeKey });
  if (!canList.length) return;

  // 数据格式化
  const applyData = _formatCanList(that, canList, { typeKey });

  const header = {
    number: { title: '单据号', display: 'show', dispay: 'show' },
    company_id: { title: '网点', display: 'show', dispay: 'show' },
    create_by: { title: '创建人', display: 'show', dispay: 'show' },
  };
  new PopUp(BatchOp, {
    title: '批量取消审核',
    tips: '取消审核',
    header,
    data: applyData,
    requestUrl: 'Basic/Approval/cancelAudit',
    getPara: dataList => {
      const theData = dataList[0];
      const req = { data: [theData] };
      return req;
    },
    finishCallback: () => that.handleRefreshPage(),
    escBind: false,
  }).show();
};

const cancelAuditMass = async (
  that,
  operation,
  getCurOperation,
  {
    buttonKey,
    customHeader,
    selectedList: cancelList, // 需要取消审核的数据，如果没有传就取列表选中
  } = {},
) => {
  console.log(operation, getCurOperation, '99999999');
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const type = theKeys.desc;
  // 是否支持批量操作
  const supportBatch = !theKeys.unsupportBatch;
  const selectKeys = [
    theKeys.applyId,
    theKeys.number || 'number',
    theKeys.bill_no || 'bill_no',
    theKeys.company_id || 'company_id',
    theKeys.create_by || 'create_by',
    'otherProps',
    'id',
    'carrier_confirm_st', // 承运商确认状态 0/null → 未发送，10 → 未确认 ， 20 → 已确认
  ];
  if (theKeys.extraKeys) selectKeys.push(...theKeys.extraKeys);
  if (getCurOperation) {
    selectKeys.push(theKeys.diff);
  }
  let selectedList = cancelList ?? that.getSelectesKey(selectKeys);
  selectedList = _.uniqWith(selectedList, _.isEqual);
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  if (!supportBatch && selectedList.length > 1) {
    showInfo(ERROR, '您只能选择一条数据进行操作！');
    return;
  }
  const flag = getIsApprovals(selectedList, theKeys.force);
  console.log('cancelAuditMass =', flag);
  let dataIndexs = [];
  let per;
  // 未开启审批流的数据需要校验下是否有相关权限。
  switch (operation) {
    // 收付款单
    case 'receipt_payment':
      per = 'cancel_pa_re_audit,review_pa_re_bill_driver_v1';
      break;
    // 日常收支
    case 'expense_or_receipt':
      per = 'cancel_check_balance';
      break;
    // 客户对账单
    case 'create_account':
      per = 'cancel_audit_customer';
      break;
    // 网点对账单
    case 'point_ac_audit_cancel':
      per = 'point_ac_audit_cancel';
      break;
    case 'create_point':
      per = 'point_ac_audit_cancel';
      break;
    case 'add_abnormal':
      per = 'auditAbnormal';
      break;
    case 'add_penalty':
      per = 'auditPenalty';
      break;
    default:
      break;
  }
  let perDesc = '';
  if (per?.split?.(',').length > 1) {
    const pers = per.split(',');
    for (let i = 0, len = pers.length; i < len; i++) {
      const hasPer = hasPermission(pers[i]);
      per = hasPer;
      if (hasPer) break;
    }
  } else {
    per = hasPermission(per);
  }

  let subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，请选择一类继续操作！';
  if (!per) {
    perDesc = '，如需审核，请联系管理员开通权限';
    subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，是否继续操作自定义审批流的单据？';
  }

  if (flag.isApproval.length && flag.notApproval.length) {
    try {
      dataIndexs = await Confirm({
        iconType: WARN,
        subTitle,
        checked: per ? [] : [0],
        disabled: per ? [] : [1],
        notice: [
          {
            text: ' 以下单据按“自定义审批流”操作：',
            child: flag.isApproval.map(v => v[theKeys.number]),
          },
          {
            text: `以下单据按“系统默认审批流”操作${perDesc}：`,
            child: flag.notApproval.map(v => v[theKeys.number]),
          },
        ],
      });
    } catch (error) {
      console.log(error);
      return;
    }
    if (dataIndexs[0] === 0) {
      selectedList = flag.isApproval;
    } else {
      selectedList = flag.notApproval;
    }
  }
  // 批量接口地址
  // 数据格式化
  let requestUrl = 'Basic/Approval/cancelAudit';
  let reqFormater = dataList => {
    const theData = dataList[0];
    const req = { data: [theData] };
    return req;
  };
  if (flag.notApproval.length && dataIndexs[0] !== 0) {
    switch (operation) {
      // 收付款单
      case 'receipt_payment':
        initEmt('rcptPytCancelAudit', that);
        return;
      // 日常收支
      case 'expense_or_receipt':
        cancelCheckBalance(
          that,
          selectedList.map(v => v.bill_id),
        );
        return;
      // 客户对账单
      case 'create_account':
        customerCancelAudit(
          that,
          selectedList.map(v => v.bill_id),
          { buttonKey },
        );
        return;
      // 网点对账单
      case 'point_ac_audit_cancel':
        pointAcCancelAudit(
          that,
          selectedList.map(v => v.bill_id),
        );
        return;
      // 异常
      case 'add_abnormal':
        requestUrl = '/Order/Abnormal/cancelAudit';
        reqFormater = dataList => {
          const theData = dataList[0];
          const req = { abnormal_id: theData.id };
          return req;
        };
        break;
      // 罚款
      case 'add_penalty':
        requestUrl = '/Order/Abnormal/cancelAudit';
        reqFormater = dataList => {
          const theData = dataList[0];
          const req = { abnormal_id: theData.id };
          return req;
        };
        break;
      case 'create_point':
        pointAcCancelAudit(
          that,
          selectedList.map(v => v.bill_id),
        );
        return;
      default:
        return;
    }
  } else {
    switch (operation) {
      // 物料发放
      case 'material_apply':
        reqFormater = dataList => {
          const theData = dataList[0];
          const applyData = [{ ...theData, table: 'material_apply_bill' }];
          const req = { data: applyData, audit: 1, apply_id: applyData.map(e => e.apply_id) };
          return req;
        };
        break;
      default:
        break;
    }
  }
  const applyID = selectedList.map(v => v[theKeys.applyId]);
  const data = selectedList.map(v => {
    let comId = v[theKeys.company_id || 'company_id'];
    let createBy = v[theKeys.create_by || 'create_by'];
    try {
      createBy = that.getEnumItem(theKeys.create_by || 'create_by', createBy);
    } catch (error) {
      createBy = '';
    }
    if (typeof comId === 'string') {
      try {
        comId = that.getEnumItem(theKeys.company_id || 'company_id', comId);
      } catch (error) {
        comId = '';
      }
    }
    comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
    createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
    return {
      ..._.pick(v, theKeys.extraKeys ?? []),
      ...getTable(getCurOperation ? getCurOperation(v) : operation),
      apply_id: v[theKeys.applyId],
      number: v[theKeys.number || 'number'],
      bill_no: v[theKeys.bill_no || 'bill_no'],
      company_id: comId,
      create_by: createBy,
      id: v.id,
    };
  });
  const handle = () => {
    if (applyID.length > 1) {
      const header = customHeader ?? {
        number: { title: '单据号', display: 'show', dispay: 'show' },
        company_id: { title: '申请组织', display: 'show', dispay: 'show' },
        create_by: { title: '申请人', display: 'show', dispay: 'show' },
      };
      new PopUp(BatchOp, {
        title: '批量取消审核',
        tips: '取消审核',
        header,
        data,
        requestUrl,
        getPara: reqFormater,
        finishCallback: () => that.handleRefreshPage(),
        escBind: false,
      }).show();
    } else {
      buttonKey && that?.disableMenuButton?.(buttonKey);
      fetchApi(requestUrl, {
        method: 'POST',
        body: {
          req: reqFormater(data),
        },
      })
        .then(res => {
          opTips({
            opType: 'cancelOrderCheck',
            orderType: type,
            resData: res,
            sucCallback: () => {
              setTimeout(() => {
                that.handleRefreshPage();
              }, 1000);
            },
            ext: {
              failedCallback: that.handleRefreshPage,
              cancelBtnName: '确定',
              customErrRule: '',
            },
          });
          // setTimeout(that.handleRefreshPage, 2000)
        })
        .catch(() => showInfo(ERROR, `取消${type}审核失败`))
        ?.finally?.(() => buttonKey && that?.enableMenuButton?.(buttonKey));
    }
  };

  let tips = `您确定要取消该${type}审核吗？`;
  if (selectedList.map(item => item.carrier_confirm_st).includes('20')) {
    tips = '您选择的承运商对账单已确认，取消审核后承运商在对账确认页面将看不到此账单，确定取消审核吗？';
  }
  showInfo(WARN, tips, true, { confirm: handle });
};
const resetVerifyMass = async (that, operation, getCurOperation) => {
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const type = theKeys.desc;
  let selectedList = that.getSelectesKey([
    'id',
    'number',
    'op_filter',
    'number',
    'state',
    'otherProps',
    'add_user_id',
    'add_company_id',
  ]);
  selectedList = _.uniqWith(selectedList, _.isEqual);
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  const flag = getIsApprovals(selectedList);
  let dataIndexs = [];
  let per;
  let tipStr = '';
  // 未开启审批流的数据需要校验下是否有相关权限。
  switch (operation) {
    case 'add_abnormal':
      per = 'auditAbnormal';
      tipStr = '待处理';
      break;
    case 'add_penalty':
      per = 'auditPenalty';
      tipStr = '待审核';
      break;
    default:
      return;
  }
  let perDesc = '';
  per = hasPermission(per);
  let subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，请选择一类继续操作！';
  if (!per) {
    perDesc = '，如需审核，请联系管理员开通权限';
    subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，是否继续操作自定义审批流的单据？';
  }
  console.log();
  if (flag.isApproval.length && flag.notApproval.length) {
    try {
      dataIndexs = await Confirm({
        iconType: WARN,
        subTitle,
        checked: per ? [] : [0],
        disabled: per ? [] : [1],
        notice: [
          {
            text: ' 以下单据按“自定义审批流”操作：',
            child: flag.isApproval.map(v => v[theKeys.number]),
          },
          {
            text: `以下单据按“系统默认审批流”操作${perDesc}：`,
            child: flag.notApproval.map(v => v[theKeys.number]),
          },
        ],
      });
    } catch (error) {
      console.log(error);
      return;
    }
    if (dataIndexs[0] === 0) {
      selectedList = flag.isApproval;
    } else {
      selectedList = flag.notApproval;
    }
  }
  // 批量接口地址
  // 数据格式化
  let requestUrl = 'Basic/Approval/resetAudit';
  let reset_rmk = '';
  let reqFormater = dataList => {
    const theData = dataList[0];
    const req = { data: [theData], reset_rmk };
    return req;
  };
  if (flag.notApproval.length && dataIndexs[0] !== 0) {
    requestUrl = '/Order/Abnormal/cancelAudit';
    reqFormater = dataList => {
      const theData = dataList[0];
      const req = { abnormal_id: theData.id, op_type: 'audit_reset', reset_rmk };
      return req;
    };
  }
  const applyID = selectedList.map(v => v[theKeys.applyId]);
  const data = selectedList.map(v => {
    let comId = v[theKeys.company_id || 'company_id'];
    let createBy = v[theKeys.create_by || 'create_by'];
    try {
      createBy = that.getEnumItem(theKeys.create_by || 'create_by', createBy);
    } catch (error) {
      createBy = '';
    }
    if (typeof comId === 'string') {
      try {
        comId = that.getEnumItem(theKeys.company_id || 'company_id', comId);
      } catch (error) {
        comId = '';
      }
    }
    comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
    createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
    return {
      ...getTable(getCurOperation ? getCurOperation(v) : operation),
      apply_id: v[theKeys.applyId],
      number: v[theKeys.number || 'number'],
      company_id: comId,
      create_by: createBy,
      id: v.id,
    };
  });
  const handle = thisDialog => {
    if (!reset_rmk) {
      showInfo(ERROR, '重置审核原因是必填项');
      return;
    }
    if (applyID.length > 1) {
      const header = {
        number: { title: '单据号', display: 'show', dispay: 'show' },
        company_id: { title: '申请组织', display: 'show', dispay: 'show' },
        create_by: { title: '申请人', display: 'show', dispay: 'show' },
      };
      new PopUp(BatchOp, {
        title: '批量重置审核',
        tips: '重置审核',
        header,
        data,
        requestUrl,
        getPara: reqFormater,
        prefixalTips: '',
        noNums: true,
        tableWidth: 600,
        tableWrapStyle: { width: '600px' },
        defineClass: 'resetVerifyMass',
        dealHeaderWidth: 180,
        finishCallback: () => {
          thisDialog.handleHide();
          that.handleRefreshPage();
        },
        escBind: false,
      }).show();
    } else {
      fetchApi(requestUrl, {
        method: 'POST',
        body: {
          req: reqFormater(data),
        },
      })
        .then(res => {
          opTips({
            opType: 'resetCheck',
            orderType: type,
            resData: res,
            sucCallback: () => {
              setTimeout(() => {
                thisDialog.handleHide();
                that.handleRefreshPage();
              }, 1000);
            },
            ext: {
              failedCallback: () => {
                thisDialog.handleHide();
                that.handleRefreshPage();
              },
              cancelBtnName: '确定',
              customErrRule: '',
            },
          });
          // setTimeout(that.handleRefreshPage, 2000)
        })
        .catch(() => showInfo(ERROR, '重置审核失败'));
    }
  };

  const title = '您确定要重置审批吗？';
  const noticeHtml = (
    <div className="reset-content">
      <div className="title">重置后，所有审批都将取消，单据变回“{tipStr}”状态</div>
      <div className="item text">
        <TextArea placeholder="请输入重置原因（必填）" onChange={e => (reset_rmk = e.target.value)} />
      </div>
    </div>
  );
  const popup = new PopUp(ShowInfo, {
    classname: 'queryer-op1',
    isShow: true,
    type: WARN,
    content: title,
    contentNotice: noticeHtml,
    autoDestroy: true,
    title: '操作确认',
    simpleMode: {
      confirm: thisDialog => handle(thisDialog),
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  popup.show();
  //  showInfo(WARN, `您确定要取消该${type}审核吗？`, true, { confirm: handle })
};
const getAuditType = (operation, applyId, number) =>
  fetchApi('Basic/Approval/getAuditType', {
    method: 'POST',
    body: {
      req: {
        data: [{ ...getTable(operation), apply_id: applyId, number }],
      },
    },
  });
const getAuditTypes = data =>
  fetchApi('Basic/Approval/getAuditType', {
    method: 'POST',
    body: {
      req: {
        data,
      },
    },
  });

export const getAuditTypesOp = async (that, operation, getCurOperation, selectData) => {
  if (!that || !selectData) return;
  // 自定义的配置信息
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const data = selectData.map(v => {
    let comId = v[theKeys.company_id || 'company_id'];
    let createBy = v[theKeys.create_by || 'create_by'];
    try {
      createBy = that.getEnumItem(theKeys.create_by || 'create_by', createBy);
    } catch (error) {
      createBy = '';
    }

    if (typeof comId === 'string') {
      try {
        comId = that.getEnumItem(theKeys.company_id || 'company_id', comId);
      } catch (error) {
        comId = '';
      }
    }
    comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
    createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
    return {
      ..._.pick(v, theKeys.extraKeys ?? []),
      number: v[theKeys.number],
      apply_id: v[theKeys.applyId],
      ...getTable(getCurOperation ? getCurOperation(v) : operation),
      bill_no: v[theKeys.bill_no || 'bill_no'],
      company_id: comId,
      create_by: createBy,
      customer_type: v.customer_type,
    };
  });
  // 后端check 是否可以审核
  const checkRes = await getAuditTypes(data);
  return checkRes;
};
/**
 * 审核 弹窗
 * @param {* listpage instance} that
 * @param {trigger operation key} operation
 * @param options 其他扩展参数
 */
const AuditWithModal = async (that, operation, getCurOperation, selectData, options) => {
  // 自定义的配置信息
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  // 是否支持批量操作
  const supportBatch = !theKeys.unsupportBatch;
  const selectKeys = [
    theKeys.applyId,
    theKeys.auditStatus,
    theKeys.number,
    theKeys.bill_no || 'bill_no',
    theKeys.company_id || 'company_id',
    theKeys.create_by || 'create_by',
    'otherProps',
    'customer_type',
  ];
  if (theKeys.extraKeys) selectKeys.push(...theKeys.extraKeys);
  if (getCurOperation) {
    selectKeys.push(theKeys.diff);
  }
  let selectedList = selectData || that.getSelectesKey(selectKeys);
  // 去重
  selectedList = _.uniqWith(selectedList, _.isEqual);
  const type = theKeys.desc || undefined;
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  if (!supportBatch && selectedList.length > 1) {
    showInfo(ERROR, '您只能选择一条数据进行操作！');
    return;
  }
  const flag = getIsApprovals(selectedList, theKeys.force, operation);
  let dataIndexs = [];
  let per;
  switch (operation) {
    // 收付款单
    case 'receipt_payment':
      per = 'review_pa_re,review_pa_re_bill_driver_v1';
      break;
    // 日常收支
    case 'expense_or_receipt':
    case 'create_expense':
    case 'create_receipt':
      per = 'check_balance';
      break;
    // 客户对账单
    case 'create_account':
      per = 'audit_customer';
      break;
    // 网点对账单
    case 'point_ac_audit':
      per = 'pointAcAudit';
      break;
    case 'create_point':
      per = 'point_ac_audit';
      break;
    default:
      break;
  }
  let perDesc = '';

  if (per?.split?.(',').length > 1) {
    const pers = per.split(',');
    for (let i = 0, len = pers.length; i < len; i++) {
      const hasPer = hasPermission(pers[i]);
      per = hasPer;
      if (hasPer) break;
    }
  } else {
    per = hasPermission(per);
  }

  let subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，请选择一类继续操作！';
  if (!per) {
    perDesc = '，如需审核，请联系管理员开通权限';
    subTitle = '无法同时操作已开启“自定义审批流”和“系统默认审批流”的单据，是否继续操作自定义审批流的单据？';
  }
  if (flag.isApproval.length && flag.notApproval.length) {
    try {
      dataIndexs = await Confirm({
        iconType: WARN,
        subTitle,
        checked: per ? [] : [0],
        disabled: per ? [] : [1],
        notice: [
          {
            text: ' 以下单据按“自定义审批流”操作：',
            child: flag.isApproval.map(v => v[theKeys.number]),
          },
          {
            text: ` 以下单据按“系统默认审批流”操作${perDesc}：`,
            child: flag.notApproval.map(v => v[theKeys.number]),
          },
        ],
      });
    } catch (error) {
      console.log(error);
      return;
    }
    if (dataIndexs[0] === 0) {
      selectedList = flag.isApproval;
    } else if (flag.notApproval) {
      selectedList = flag.notApproval;
    }
  }
  let data = selectedList.map(v => {
    let comId = v[theKeys.company_id || 'company_id'];
    let createBy = v[theKeys.create_by || 'create_by'];
    try {
      createBy = that.getEnumItem(theKeys.create_by || 'create_by', createBy);
    } catch (error) {
      createBy = '';
    }

    if (typeof comId === 'string') {
      try {
        comId = that.getEnumItem(theKeys.company_id || 'company_id', comId);
      } catch (error) {
        comId = '';
      }
    }
    comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
    createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
    return {
      ..._.pick(v, theKeys.extraKeys ?? []),
      number: v[theKeys.number],
      apply_id: v[theKeys.applyId],
      ...getTable(getCurOperation ? getCurOperation(v) : operation),
      bill_no: v[theKeys.bill_no || 'bill_no'],
      company_id: comId,
      create_by: createBy,
      customer_type: v.customer_type,
    };
  });
  if (flag.notApproval.length && dataIndexs[0] !== 0) {
    switch (operation) {
      // 收付款单
      case 'receipt_payment':
        receiptPaymentAudit(that, selectedList);
        break;
      // 日常收支
      case 'expense_or_receipt':
        checkBalance(
          that,
          selectedList.map(v => v.bill_id),
          data,
        );
        // auditExpense({ that, data, selectedList })
        break;
      // 客户对账单
      case 'create_account':
        cusAudit(that, selectedList);
        break;
      // 网点对账单
      case 'point_ac_audit':
        pointAudit(
          that,
          selectedList.map(v => v.bill_id),
        );
        break;
      case 'create_point':
        pointAudit(
          that,
          selectedList.map(v => v.bill_id),
        );
        break;
      default:
        return;
    }
    return;
  }
  const { buttonKey } = options ?? {};
  buttonKey && that?.disableMenuButton?.(buttonKey);
  // 后端check 是否可以审核
  const checkRes = await getAuditTypes(data).finally(() => buttonKey && that?.enableMenuButton?.(buttonKey));
  const auditType = checkRes.res.data;
  const { appoint } = checkRes.res;
  const operations = checkRes.res.operation;
  const operation_dict = checkRes.res.operation_dict || {};
  const isExpenseBill = ['expense_or_receipt', 'create_expense', 'create_receipt'].filter(v =>
    (checkRes.res.operation || []).includes(v),
  );
  // 判断审批 拒绝/驳回
  const approvalConfig = (window.systemConfig && window.systemConfig.approval_config) || {};
  const success_operate = _.uniq(Object.values(operation_dict));
  const operate = _.intersection(Object.keys(approvalConfig), Object.values(success_operate));
  const rightOperate =
    (success_operate.length === operate.length && '驳回') || (operate.length === 0 && '拒绝') || '拒绝/驳回';
  const buttonText = {
    left: auditType && auditType !== 'audit' ? '确认' : '通过',
    right: auditType && auditType !== 'audit' ? '' : rightOperate,
  };

  let elReamrk;
  let form;
  let btn1;
  let btn2;
  let dialogRef;
  let slideDialogRef;
  // 防止按钮重复点击 处理
  const handleBtn = (loading = true) => {
    btn1 &&
      btn1.setState({
        // 操作按钮loading
        loading,
      });
    btn2 &&
      btn2.setState({
        // 取消按钮 disabled
        disabled: loading,
      });
  };
  let applyId = data.map(v => v.apply_id);

  // 添加埋点
  const processedId = {};
  const hasRepeat = new Set(applyId).size !== applyId.length;
  const handleReport = param => {
    const extra = {
      data,
      operation,
      buttonKey,
      ...param,
    };
    sentryReporter?.setLevel('error').setExtras(extra).rdcReport({
      module: 'AuditWithModal',
      description: 'This is a annoying report.',
    });
  };
  if (hasRepeat) handleReport();

  // 提交表单获取结果之后刷新page
  const handleSubmit = async (result, vals, isRebut) => {
    const { opinion, appoint_user, approval_img } = vals;
    const approvalImgs = { 'approval_img[]': approval_img, 'add_img[]': approval_img };
    const uploadFiles = createUploadFiles(approval_img);
    if ((!result || +result != 1) && !opinion && !isRebut) {
      showInfo(ERROR, '请输入审批意见！');
      return;
    }
    handleBtn();
    const approval_config =
      (window.systemConfig &&
        window.systemConfig.approval_config &&
        Object.keys(window.systemConfig.approval_config)) ||
      [];

    let check_rebut_direct = false;
    const onChangeRebutDirect = checked => {
      check_rebut_direct = checked;
    };

    // 驳回时需要确认弹窗
    if (isRebut == 1) {
      // eslint-disable-next-line no-shadow
      const result = await rebutConfirm({ onChangeRebutDirect, rebut_direct: checkRes?.res?.check_rebut_direct });
      if (!result) return handleBtn(false);
    }

    if (data.length > 1) {
      // 自定义表头
      const { customHeader } = options ?? {};
      const header = customHeader ?? {
        bill_no: { title: '单据号', display: 'show', dispay: 'show' },
        company_id: { title: '申请组织', display: 'show', dispay: 'show' },
        create_by: { title: '申请人', display: 'show', dispay: 'show' },
      };

      dialogRef.handleHide();

      new PopUp(BatchOp, {
        title: '批量审核',
        tips: '审核',
        header,
        data,
        requestUrl: 'Basic/Approval/audit',
        getParaType: 'body',
        operations,
        getPara: dataList => {
          const theData = dataList[0] || {};

          // 添加埋点
          if (processedId[theData.apply_id]) {
            handleReport({ cur_apply_id: theData.apply_id, processedId });
          } else {
            processedId[theData.apply_id] = 1;
          }

          const req = {
            audit:
              result && +result === 1
                ? '1'
                : (approval_config.includes(operation_dict[theData.apply_id]) && '4') || '3',
            ...vals,
            data: [theData],
            check_rebut_direct,
            is_batch_op: true, // 仅用来标识 是否 通过批量弹框操作的，后端不会使用
          };

          const body = new FormData();
          if (uploadFiles) {
            req.upload_files = {
              approval_img: uploadFiles,
            };
            delete req.approval_img;
          } else if (approvalImgs && Object.keys(approvalImgs).length) {
            Object.entries(approvalImgs).forEach(([key, imgsObj]) => {
              Object.entries(imgsObj).forEach(([, imgObj]) => {
                body.append(key, imgObj.formdata);
              });
            });
            req.approval_img = Object.entries(approvalImgs).map(([, imgsObj]) => Object.keys(imgsObj))[0] || [];
          }

          body.append('req', JSON.stringify(req));
          return body;
        },
        finishCallback: () => that.handleRefreshPage(),
        escBind: false,
      }).show();
    } else {
      const audit =
        result && +result === 1
          ? '1'
          : (approval_config.includes(operation_dict[data[0] && data[0].apply_id]) && '4') || '3';
      Audit(operation, applyId, audit, opinion, data, approvalImgs, appoint_user, {
        extReq: { check_rebut_direct },
      }).then(res => {
        dialogRef.handleHide();
        if (res.errno === 0) {
          showInfo(CHECK, '操作成功！');
          setTimeout(() => {
            that.handleRefreshPage();
          }, 2000);
          return;
        }
        opTips({
          opType: 'audit',
          orderType: type,
          resData: res,
          sucCallback: () => {
            setTimeout(() => {
              that.handleRefreshPage();
            }, 2000);
          },
        });
      });
    }
  };
  let dialog = {};
  // 弹框
  const popUpParam = {
    popName: `openRecExpPanel${applyId}`,
    pageType: 'receipt',
    titleName: '审核',
    popContainer: that,
    operation: ['batch_assess_customer_apply', 'batch_assess_trans_apply'].includes(operation)
      ? operation
      : 'create_receipt', // 运力考核传batch_assess_trans_apply
    operations,
    applyId,
    ref: r => (slideDialogRef = r),
    // opCallback: that.handleRefreshData,
    that,
    data,
    rebut_direct: checkRes?.res?.check_rebut_direct,
    ...options,
  };

  if (data.length > 1) {
    dialog = new PopUp(ModalDialog, {
      content: (
        <AuditModal
          showTip={buttonText.right.indexOf('驳回') != -1}
          appoint={appoint}
          ref={ref => (form = ref)}
          showUploadImg={theKeys.showUploadImg}
        />
      ),
      bottom: (
        <div>
          <Button
            type="primary"
            ref={ref => (btn1 = ref)}
            onClick={() => {
              const vals = form.onSubmit();
              if (
                vals &&
                buttonText?.left?.indexOf?.('驳回') != -1 &&
                [
                  'loan_audit',
                  'create_account', // 付款单列表批量
                  'expense_or_receipt', // 收支单汇总批量
                  'receipt_payment', // 收款单-订单对账单批量
                ].includes(operation)
              ) {
                handleSubmit(true, vals, true);
              } else {
                handleSubmit(true, vals, false);
              }
            }}
          >
            {buttonText.left}
          </Button>
          {buttonText.right && (
            <Button
              type="default"
              ref={ref => (btn2 = ref)}
              onClick={() => {
                const vals = form.onSubmit();
                if (
                  vals &&
                  buttonText?.right?.indexOf?.('驳回') != -1 &&
                  [
                    'loan_audit',
                    'create_account', // 付款单列表批量
                    'expense_or_receipt', // 收支单汇总批量
                    'receipt_payment', // 收款单-订单对账单批量
                  ].includes(operation)
                ) {
                  handleSubmit(false, vals, true);
                } else {
                  handleSubmit(false, vals, false);
                }
              }}
            >
              {buttonText.right}
            </Button>
          )}
        </div>
      ),
      contentStyle: { width: 500 },
      isShow: true,
      ref: r => (dialogRef = r),
      isModal: true,
      title: '操作确认',
    });
  } else {
    dialog = new PopUp(applySlideDrager, popUpParam);
  }

  if (!_.isEqual(_.get(checkRes, 'res.failed_detail', []), [])) {
    opTips({
      opType: 'orderCheck',
      orderType: type,
      resData: checkRes,
      continueCallback: (successIds, cb) => {
        cb();
        applyId = successIds;
        data = data.filter(v => successIds.find(id => id === v.apply_id));
        dialog.show();
      },
      ext: {
        cancelBtnName: '确定',
        customErrRule: '',
      },
    });
  } else {
    if (data.length === 1 && ['expense_or_receipt', 'create_expense', 'create_receipt'].includes(operation)) {
      auditExpense({
        that,
        auditType,
        appoint,
        data,
        selectedList,
        operations,
        rebut_direct: checkRes?.res?.check_rebut_direct,
      });
      return;
    }
    dialog.show();
  }
};

/**
 * 申请 & 审核
 * @param {* 申请id} applyId
 * @param {* 是否是申请} isApply
 */
const approvalProcess = ({ applyId, isApply, isAudit, isUpdate, hiddenOrderInfo, questKey, that }) => {
  if (isApply) {
    return ({ operation, data, uploadImgs, upload_files }) =>
      Apply(operation, data, uploadImgs, applyId, hiddenOrderInfo, questKey, that, upload_files);
  } else if (isAudit) {
    return ({ operation, audit, opinion, uploadImgs, data, upload_files }) =>
      Audit(operation, applyId, audit, opinion, data, uploadImgs, undefined, {}, upload_files);
  } else if (isUpdate) {
    return ({ operation, data, uploadImgs }) => updateApply(operation, data, uploadImgs, applyId);
  }
  return console.log('你在搞事情');
};
/**
 * 审批跟踪
 * @param {*行数据} data
 * @param {*触发操作} operation
 */
const auditLog = (data, operation, that) => {
  const theKeys = auditKeyMap[operation] || auditKeyMap.default;
  const id = data[theKeys.applyId];
  const category = _.get(that, 'currFetchParamCache.category');
  const tab = _.get(that, 'currFetchParamCache.tab');
  // 是否存在付款单审批流
  const { bill_id } = data;
  AuditTrail(id, getTable(operation), bill_id, category, tab);
};
const batchModifyAuditLog = (that, key, column, row) => {
  auditLog(row, 'batch_modify_apply');
};
const batchModifyCancel = (that, key, column, row) => {
  cancelApply(that, 'batch_modify_apply', { applyId: row.id, desc: '改单申请' });
};
const cancelInvoice = (that, key) => {
  const { tab } = that.tableInfo;
  const operation = tab.includes('apply') ? 'user_invoice_apply' : 'customer_invoice_apply';
  cancelApply(that, operation);
};
const auditSettleApplyInvoice = (that, key) => {
  AuditWithModal(that, 'user_invoice_apply');
};
const cancelAuditSettleApplyInvoice = (that, key) => {
  cancelAuditMass(that, 'user_invoice_apply');
};
const auditSettleAskInvoice = (that, key) => {
  AuditWithModal(that, 'customer_invoice_apply');
};
const cancelAuditSettleAskInvoice = (that, key) => {
  cancelAuditMass(that, 'customer_invoice_apply');
};
const batchAssessAuditAssess = (that, key, col, row) => {
  const operation = that.tableInfo.tab.includes('customer')
    ? 'batch_assess_customer_apply'
    : 'batch_assess_trans_apply';
  AuditWithModal(that, operation, undefined, [row]);
};
const batchAssessCancelAssess = (that, key, col, row) => {
  const operation = that.tableInfo.tab.includes('customer')
    ? 'batch_assess_customer_apply'
    : 'batch_assess_trans_apply';
  cancelAudit(that, operation, { applyId: row.id });
};
const batchAssessDeleteAssess = async (that, key, col, row) => {
  if (!(await confirm(WARN, '您确定要删除该运力考核审核吗？', {}, '操作确认'))) {
    return;
  }
  const req = {
    apply_id: row.id,
    operation: 'batch_assess_trans_apply',
  };
  const res = await fetchApi('Basic/Approval/cancelApply', { method: 'POST', body: { req } });
  if (+res.errno === 0) {
    showInfo(CHECK, '删除成功');
    setTimeout(() => {
      that.handleRefreshPage();
    }, 2000);
  } else {
    return showInfo(ERROR, res.errmsg);
  }
};

//  审核接口 1通过 4驳回
const toAudit = async (audit, opinionVal, row, that) => {
  const { car_batch, batch_apply_id } = row || {};
  try {
    const req = {
      data: [{ number: car_batch, apply_id: batch_apply_id, operation: 'batch_create_apply', opinion: opinionVal }],
      operation: 'batch_create_apply',
      audit,
      opinion: opinionVal,
    };
    const reqUrl = '/Basic/Approval/audit';
    that.handleStartLoading();
    const fetchRes = await fetchApi(reqUrl, { method: 'POST', body: { req } });
    that.handleStopLoading();
    if (fetchRes.errno !== 0) {
      const errInfo = fetchRes.res?.failed_detail?.length ? fetchRes.res?.failed_detail?.[0]?.msg : fetchRes.errmsg;
      return showInfo(ERROR, errInfo);
    } else {
      showInfo(CHECK, '审核操作成功');
      setTimeout(() => {
        that.handleRefreshData();
      }, 1000);
    }
  } catch (e) {
    showInfo(ERROR, '网络错误');
  }
};

// 确认订单 - 批量审核
export const batchCreateAudit = that => {
  const selects = that.vTable.getStateSelectes() || [];
  const data = that.getData();
  const enumerations = that.getTableEnum();
  const selectsData = selects.map(index => data[index]);
  let opinionVal = '';
  const opinionChange = e => {
    opinionVal = e.target.value;
  };
  if (!checkSelected(selectsData)) return;

  // 批量操作弹窗
  const showBatchOP = (audit, originData) => {
    const param = {
      title: '批量操作',
      requestUrl: '/Basic/Approval/audit',
      tips: '审核',
      dealHeaderWidth: 150,
      enumerations,
      header: {
        car_batch: { title: '运单号', width: 120 },
        create_time: { title: '创建时间', width: 100 },
      },
      data: originData,
      escBind: false,
      getPara: dataList => {
        const { car_batch, batch_apply_id } = dataList[0] || {};
        return {
          data: [{ number: car_batch, apply_id: batch_apply_id, operation: 'batch_create_apply', opinion: opinionVal }],
          operation: 'batch_create_apply',
          audit,
          opinion: opinionVal,
        };
      },
      finishCallback: () => {
        that.handleRefreshData();
      },
    };
    new PopUp(BatchOp, param).show();
  };

  // 二次确认弹窗
  new PopUp(ShowInfo, {
    classname: 'detail_info',
    type: WARN,
    title: '操作确认',
    content: (
      <DialogDetail
        noticeTitle={<span />}
        ext={{
          specialTips: '确认批量审核吗？',
          reason: (
            <div className="task-check-content">
              <div className="check-remark task-check-item">
                <label className="item-txt"> 审批意见： </label>
                <TextArea style={{ width: '300px', marginTop: '5px', height: '80px' }} onChange={opinionChange} />
              </div>
            </div>
          ),
        }}
      />
    ),
    simpleMode: {
      confirm: thisDialog => {
        thisDialog.handleHide();
        showBatchOP(1, selectsData);
      },
      cancel: thisDialog => {
        thisDialog.handleHide();
      },
    },
  }).show();
};

const batchBatchCreateAudit = (that, key, column, row) => {
  let opinionVal = '';
  const opinionChange = e => {
    opinionVal = e.target.value;
  };

  const content = (
    <div style={{ color: 'black' }}>
      <p style={{ margin: '6px 0', fontSize: '14px' }}>确认审核吗？</p>
      <div>
        <p style={{ color: 'red' }}>配载生成的运单,驳回后会被删除!</p>
        <label style={{ fontWeight: '400' }}> 审批意见： </label>
        <TextArea style={{ width: '300px', marginTop: '5px', height: '80px' }} onChange={opinionChange} />
      </div>
    </div>
  );

  new PopUp(ShowInfo, {
    type: WARN,
    content,
    autoDestroy: true,
    title: '操作确认',
    popName: 'customer-key',
    buttons: [
      {
        name: '通过',
        btnType: 'primary',
        cb: async thisDialog => {
          // 显微镜埋点
          dataAnalyticTrack(['运单确认', '审核通过']);
          thisDialog.handleHide();
          toAudit(1, opinionVal, row, that);
        },
      },
      {
        name: '驳回',
        btnType: 'default',
        cb: thisDialog => {
          if (!opinionVal) return showInfo(ERROR, '请输入审批意见！');
          // 显微镜埋点
          dataAnalyticTrack(['运单确认', '审核驳回']);
          thisDialog.handleHide();
          toAudit(4, opinionVal, row, that);
        },
      },
    ],
    closable: true,
    maskClosable: true,
  }).show();
};

/**
 * 整体设置
 */
const globalSetting = () => {
  const param = {
    popName: 'globalSetting',
  };
  new PopUp(GlobalSetting, { ...param }).show();
};

function init(emt) {
  emt.on('createApprovalFinance', (that, ...rest) => {
    // 新增财务审批
    ApprovalEditor.show({ type: 1, opType: 'add' });
  });
  emt.on('createApprovalWaybill', (that, ...rest) => {
    // 新增运单审批
    console.log(rest);
    ApprovalEditor.show({ type: 2, opType: 'add' });
  });
  emt.on('createApprovalBase', (that, ...rest) => {
    // 新增基础数据审批
    ApprovalEditor.show({ type: 3, opType: 'add' });
  });
  emt.on('approvalUpdate', onUpdate);
  emt.on('approvalDelete', (listPage, ...rest) => opearte({ type: config.delete, listPage }, ...rest));
  emt.on('approvalDisable', (listPage, ...rest) => opearte({ type: config.disable, listPage }, ...rest));
  emt.on('approvalEnable', (listPage, ...rest) => opearte({ type: config.enable, listPage }, ...rest));
  emt.on('overallSetting', globalSetting);
  emt.on('approvalApprovalRollbackDef', onRollback); // 恢复默认
  emt.on('approvalApprovalName', viewApproval);
  emt.on('batchModifyAuditLog', batchModifyAuditLog);
  emt.on('batchModifyCancel', batchModifyCancel);
  emt.on('auditSettleAuditApplyInvoice', auditSettleApplyInvoice);
  emt.on('cancelAuditSettleAuditApplyInvoice', cancelAuditSettleApplyInvoice);
  emt.on('auditSettleAuditAskInvoice', auditSettleAskInvoice);
  emt.on('cancelAuditSettleAuditAskInvoice', cancelAuditSettleAskInvoice);

  emt.on('cancelSettleApplyInvoice', cancelInvoice);
  emt.on('deleteInvoice', cancelInvoice);
  emt.on('batchAssessAuditAssess', batchAssessAuditAssess);
  emt.on('batchAssessCancelAssess', batchAssessCancelAssess);
  emt.on('batchAssessDeleteAssess', batchAssessDeleteAssess);
  emt.on('batchFeeApprovalBatchFeeAuditLog', (that, key, column, row) => auditLog(row, 'fee_report_apply', that));
  //  运单确认 跟踪
  emt.on('batchBatchCreateAuditLog', (that, key, column, row) => auditLog(row, 'batch_create_apply', that));
  //  运单确认 审核
  emt.on('batchBatchCreateAudit', batchBatchCreateAudit);
  //  运单确认 批量审核
  emt.on('batchCreateAudit', batchCreateAudit);
}
export {
  init,
  auditLog,
  approvalProcess,
  AuditWithModal,
  cancelAudit,
  cancelApply,
  cancelApplyMass,
  cancelAuditMass,
  applyOperation,
  newCheckMass,
  shouldDoAudit,
  Apply,
  Audit,
  ApplyMass,
  getAuditType,
  updateApply,
  auditKeyMap,
  getAuditTypes,
  resetVerifyMass,
  cancelAuditUniversal,
};
